body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Urbanist", "sans-serif";
}

.root {
  background-color: #f9f9f9;
  font-family: Urbanist, sans-serif;
}

.tableHeader {
  background-color: #b930e6;
  color: white;
  font-family: "Urbanist", "sans-serif";
  font-size: 1.1em;
  font-weight: 900 important!;
}

.tableHeader:last-child > .MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-root {
  border-radius: 8px !important;
}

.MuiDataGrid-columnHeaders {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.navLink {
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  margin-right: 18px;
  font-family: "Urbanist", "sans-serif";
  font-size: 18px;
  margin-bottom: 1px;
}

.navLinkMenu {
  color: #333;
  text-decoration: none;
  font-size: 12px;
}

#year-select {
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  margin-right: 18px;
  font-family: "Urbanist", "sans-serif";
  font-size: 18px;
  margin-bottom: 1px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #26c6f5 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin: 2px !important;
}

.css-h9rynr-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 1.05em;
  vertical-align: middle;
}

@media (max-width: 900px) {
  .css-h9rynr-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    font-size: 0.65em;
    color: #333 !important;
    padding-top: 5px;
  }
}

.hoverPointer {
  cursor: pointer;
}

.rowapproved {
  background-color: #26c6f5;
  color: #fff;
  font-weight: 700;
}

.rowapproved:hover {
  background-color: #26c6f5 !important;
  color: #fff;
  font-weight: 700;
}

.rowdenied {
  background-color: #c2310c;
  color: #fff;
  font-weight: 700;
}

.rowunpaid {
  background-color: #711919;
  color: #fff;
  font-weight: 700;
}

.rowunpaid:hover {
  background-color: #8d2525 !important;
  color: #fff;
  font-weight: 700;
}

.rowdenied:hover {
  background-color: #c2310c !important;
  color: #fff;
  font-weight: 700;
}

.rowcancelled {
  background-color: #ddd;
  color: #000;
  font-weight: 400;
}

.taken {
  background-color: #f6e649;
  border-radius: 4px;
  padding: 4px;
}

/* .MuiDataGrid-row:hover{
  background-color: #aaa !important;
} */

.fc-h-event {
  cursor: pointer;
}

.tippy-box {
  background-color: #efefef;
  padding: 5px;
  border-radius: 5px;
  color: #333;
  font-size: ".8rem";
}

.MuiPickersToolbar-penIconButton{
  display: none !important;
}
